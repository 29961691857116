import { Injectable } from '@angular/core';
import {of, Subject} from 'rxjs';
import { HttpClient} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {ErrorHandlingService} from '../error-handling/error-handling.service';
import * as moment from 'moment';
const apiUrl = environment.serverAPI;

interface Convention {
    id: number;
    name: string;
    event_type: number;
    start_date: string;
}

@Injectable()
export class ConventionService {
    public conventionsSub: Subject<Convention[]>;
    public convtSub: Subject<Convention>;

    private url = `${apiUrl}/conventions`;

    constructor(private http: HttpClient, private errorService: ErrorHandlingService) {
        this.conventionsSub = new Subject<Convention[]>();
        this.convtSub = new Subject<Convention>();
    }

    public fetchConventions(params) {
        if (!params.conventionDate) {
            return 'No Date provided';
        }
        const query = this.buildQuery(params.conventionDate.toISOString(), params);

        return this.http.get(`${this.url}${query}`)
            .pipe(
                map(res => res as Convention[])
            )
            .subscribe(res => this.conventionsSub.next(res));
    }

    public findById(params) {
        return this.http.get(`${this.url}/${params}`)
            .pipe(
                map(res => res as Convention),
                catchError(err => {
                    throw {err: err};
                })
            ).subscribe( res => {
                return this.convtSub.next(res);
            }, err => {
                return this.errorService.handleError(err.err);
            });
    }

    private buildQuery(date, params) {
        let query = `?date=${date}`;
        if (params.conventionState) query += `&state=${params.conventionState}`;
        return query;
    }

    public save(data) {
        if (data._id) {
            return this.updateConvention(data);
        }else {
            return this.createConvention(data);
        }
    }

    private createConvention(data) {
        return this.http.post(this.url, data)
            .pipe(
                map(res => res as Convention)
            );
    }

    public updateConvention(data) {
        return this.http.put(`${this.url}/${data._id}`, data)
            .pipe(
                map(res => res as Convention)
            );
    }

    public getConventionsForCalendar() {
        return this.http.get(`${apiUrl}/conventions-for-calendar`)
            .pipe(
                map(res => res )
            );
    }
    public setEventProps(evtVals) {
        const event = {
            city: null,
            state: null,
            language: null,
            name_date: null,
            start_date: null,
            end_date: null,
            is_active: true,
            description: null
        };
        const newEvent = Object.assign(event, evtVals);
        newEvent.eventDate = new Date(newEvent.eventDate);

        if (newEvent.eventStartTime) {
            const mom = moment.tz(newEvent.eventDate, 'America/Los_Angeles');
            const startTime = this.formatTime(newEvent.eventStartTime);
            const endTime =  this.formatTime(newEvent.eventEndTime);
            let month;
            let day;
            let year;

            // newEvent.start_date = new Date(newEvent.eventDate.setHours( startTime.hour, startTime.minutes));
            newEvent.start_date = mom.hour(parseInt(startTime.hour, 10)).minutes(parseInt(startTime.minutes, 10));
            newEvent.start_date = newEvent.start_date.subtract(!newEvent.start_date.isDST() ? 1 : 0, 'hours').toISOString();

            // newEvent.end_date = new Date(newEvent.eventDate.setHours( endTime.hour, endTime.minutes)).toISOString();
            newEvent.end_date = mom.hour(parseInt(endTime.hour, 10)).minutes(parseInt(endTime.minutes, 10));
            newEvent.end_date = newEvent.end_date.subtract(!newEvent.end_date.isDST() ? 1 : 0, 'hours').toISOString();

            month = newEvent.eventDate.getMonth() + 1;
            day = newEvent.eventDate.getDate();
            year = newEvent.eventDate.getFullYear();
            newEvent.name_date = parseInt(year +  (month > 9 ? '' : '0') + month + (day > 9 ? '' : '0') + day, 10);
            delete newEvent.eventStartTime;
            delete newEvent.eventEndTime;
            delete newEvent.eventDate;
        }

        return newEvent;
    }

    private formatTime(time) {
        let hours = Number(time.match(/^(\d+)/)[1]);
        const minutes = Number(time.match(/:(\d+)/)[1]);
        const AMPM = time.toLowerCase().match(/am|pm/)[0];

        if ( AMPM === 'pm' && hours < 12) hours = hours + 12;
        if ( AMPM === 'am' && hours === 12) hours = hours - 12;
        const sHours = hours.toString();
        const sMinutes = minutes.toString();
        return {
            hour: sHours,
            minutes: sMinutes
        };
    }

}


